<template>
  <div class="col">
    <div class="card p-3 handler_student_data">
      <div class="d-flex justify-content-center mb-3">
        <img
          class="img_ava border-dark"
          :src="image(studentsData.image, studentsData.gender)"
          :alt="studentsData.name"
          draggable="false"
        />
      </div>

      <div class="handler_container">
        <div class="tabs_heading">
          <button
            :class="{ is_active: activeTab == 1 }"
            class="our_buttons"
            @click="handlerActiveTab(1)"
          >
            {{ $t("Student personal data") }}
          </button>
          <button
            :class="{ is_active: activeTab == 2 }"
            class="our_buttons"
            @click="handlerActiveTab(2)"
          >
            {{ $t("admin_dashboard_type.change_password") }}
          </button>
        </div>
        <div v-if="activeTab == 1">
          <div>
            <el-form
              v-loading="$store.getters['user/usersLoad']"
              :model="editUser"
              ref="editedUserRef"
              :rules="editedUserRules"
              label-position="top"
            >
              <div class="row">
                <div class="col-md-6">
                  <el-form-item :label="$t('Global.name')" prop="name">
                    <el-input
                      v-model="editUser.name"
                      style="width: 100%"
                      :placeholder="$t('Global.name')"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item :label="$t('Global.gender')" prop="gender">
                    <el-radio-group v-model="editUser.gender">
                      <el-radio :label="1" @click="editUser.gender = 1">{{
                        $t("admin_dashboard_type.male")
                      }}</el-radio>
                      <el-radio :label="2" @click="editUser.gender = 2">{{
                        $t("admin_dashboard_type.female")
                      }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('Global.email')"
                    prop="email"
                    :rules="[
                      {
                        required: true,
                        type: 'email',
                        message: `${this.$t('correct_email_required')}`,
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <el-input
                      type="email"
                      v-model="editUser.email"
                      style="width: 100%"
                      :placeholder="$t('Global.email')"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('admin_dashboard_type.age')"
                    prop="age_group"
                  >
                    <!-- v-model="editUser.age_group" -->
                    <el-select
                      v-model="editUser.age_group"
                      clearable
                      :placeholder="$t('admin_dashboard_type.age')"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="(item, index) in ages"
                        :key="index"
                        :label="item.label"
                        :value="item.val"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
<!--
                <div class="col-md-6">
                  <el-form-item :label="$t('Global.country')">
                    <el-input
                      v-model="editUser.country"
                      style="width: 100%"
                      :placeholder="$t('Global.country')"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item :label="$t('admin_dashboard_type.city')">
                    <el-input
                      v-model="editUser.city"
                      style="width: 100%"
                      :placeholder="$t('admin_dashboard_type.city')"
                    ></el-input>
                  </el-form-item>
                </div> -->
                <div class="col-md-6">
                  <el-form-item :label="$t('admin_dashboard_type.school')">
                    <el-input
                      v-model="editUser.school"
                      style="width: 100%"
                      :placeholder="$t('admin_dashboard_type.school')"
                      readonly
                      class="readonly-input"
                    >
                      <template slot="prepend">
                        <i class="fas fa-school"></i>
                      </template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item :label="$t('Class')">
                    <el-input
                      v-model="editUser.grade"
                      style="width: 100%"
                      :placeholder="$t('Class')"
                      readonly
                      class="readonly-input"
                    >
                      <template slot="prepend">
                        <i class="fas fa-chalkboard"></i>
                      </template>
                    </el-input>
                  </el-form-item>
                </div>

              </div>
            </el-form>
            <div
              slot="footer"
              class="dialog-footer d-flex justify-content-start"
            >
              <el-button
                class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize my-1"
                type="primary"
                @click="save()"
                >{{ $t("save") }}
              </el-button>
            </div>
          </div>
        </div>
        <div style="min-height: 350px" v-if="activeTab == 2">
          <div>
            <el-form
              v-loading="$store.getters['user/usersLoad']"
              :model="passData"
              ref="editedPasswordRef"
              :rules="editedPasswordRules"
              label-position="top"
            >
              <div class="row">
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('admin_dashboard_type.password')"
                    prop="password"
                  >
                    <el-input
                      v-model="passData.password"
                      style="width: 100%"
                      :placeholder="$t('admin_dashboard_type.password')"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
            <div
              slot="footer"
              class="dialog-footer d-flex justify-content-start"
            >
              <el-button
                class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize my-1"
                type="primary"
                @click="savePassword()"
                >{{ $t("save") }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//
import Swal from "sweetalert2";
export default {
  data() {
    return {
      editUser: {
        id: this.$route.params.id,
        gender: null,
        name: null,
        country: null,
        email: null,
        city: null,
        school: null,
        grade: null,
        age_group: null,
      },
      passData: {
        password: null,
        confirm_password: null,
      },
      editedUserRules: {
        name: [
          {
            required: true,
            message: this.$t("name_required"),
            trigger: "blur",
          },
        ],

        gender: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        age_group: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            type: "email",
            message: `${this.$t("correct_email_required")}`,
            trigger: ["blur", "change"],
          },
        ],
      },
      editedPasswordRules: {
        password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        confirm_password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
      },
      ages: [
        {
          label: this.$t("less_than_6"),
          val: 0,
        },
        {
          label: 6,
          val: 1,
        },
        {
          label: 7,
          val: 2,
        },
        {
          label: 8,
          val: 3,
        },
        {
          label: 9,
          val: 4,
        },
        {
          label: 10,
          val: 5,
        },
        {
          label: 11,
          val: 6,
        },
        {
          label: 12,
          val: 7,
        },
        {
          label: this.$t("more_than_12"),
          val: 8,
        },
      ],
      activeTab: 1,
    };
  },
  computed: {
    studentsData() {
      return this.$store.getters["user/get_show_student_profile"];
    },
  },
  mounted() {
    this.getStudentsData();
    // this.$store.dispatch("language/getLanguages");
  },
  methods: {
    getStudentsData() {
      this.$store
        .dispatch("user/handler_show_student_profile", this.$route.params.id)
        .then((res) => {
          this.editUser.gender = res.user.gender;
          this.editUser.name = res.user.name;
          this.editUser.country = res.user.country;
          this.editUser.email = res.user.email;
          this.editUser.school = res.user.school;
          this.editUser.grade = res.user.grade;
          this.editUser.city = res.user.city;
          this.editUser.age_group = res.user.age_group;

          this.ages.forEach((element) => {
            if (res.user.age_group == element.val) {
              this.editUser.age_group = element.val;
              console.log(element);
            }
            console.log(this.editUser.age_group);
          });
        });
    },
    save() {
      this.$refs["editedUserRef"].validate((valid) => {
        if (valid) {
          // this.$store.dispatch("user/handler_update_student_password", {
          //   id: this.$route.params.id,
          //   query: this.passData,
          // });
          // this.ages.forEach((element) => {
          //   if (element.label === this.editUser.age_group) {
          //     this.editUser.age_group = element.val;
          //     console.log(element);
          //   }
          //   console.log(this.editUser.age_group);
          // });
          this.$store
            .dispatch("user/save", {
              query: this.editUser,
            })
            .then(() => {
              Swal.fire({
                text: `${this.$t("success_edited")}`,
                confirmButtonText: `${this.$t("confirm")}`,
                icon: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
              this.getStudentsData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    savePassword() {
      this.$refs["editedPasswordRef"].validate((valid) => {
        if (valid) {
          this.passData.confirm_password = this.passData.password;

          this.$store
            .dispatch("user/handler_update_student_password", {
              id: this.$route.params.id,
              query: this.passData,
            })
            .then(() => {
              (this.passData.password = null),
                (this.passData.confirm_password = null),
                Swal.fire({
                  text: `${this.$t("success_edited")}`,
                  confirmButtonText: `${this.$t("confirm")}`,
                  icon: "success",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false,
                });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //
    image(image, gender) {
      if (image) {
        return `https://justtolearn.com/api/users/cover/${image}`;
      } else if (gender == "1") {
        return "/static/img/avater.png";
      } else {
        return "/static/img/avatar-female.png";
      }
    },
    handlerActiveTab(anyNum) {
      this.activeTab = anyNum;
    },
    handlerAgeGroupForStudent() {
      this.ages.forEach((element) => {
        if (element.label === this.editUser.age_group) {
          this.editUser.age_group = element.val;
          console.log(element);
        }
      });
      console.log(this.editUser.age_group);
      // ages
      // editUser.age_group
    },
  },
};
</script>

<style lang="scss" scoped>
.readonly-input {
  background-color: #f9f9f9;
  color: #333;
  border: 1px solid #ccc;
  cursor: not-allowed;
  .el-input__inner {
    padding-left: 2em; /* Adjust if necessary to make room for the icon */
  }
}
.el-select-dropdown__item {
  padding: 5px 40px;
  text-align: right;
}
.handler_container {
  .tabs_heading {
    display: flex;
    gap: 15px;
    padding: 10px 0;
    border-bottom: 3px solid #e4e4e4;
    .our_buttons {
      border: none;
      background: none;
      color: #777;
      position: relative;
      &::before {
        position: absolute;
        right: 0;
        content: "";
        background: #409eff;
        width: 100%;
        height: 3px;
        bottom: -12px;
        display: none;
      }
      &.is_active {
        &::before {
          display: block;
        }
      }
    }
  }
}
::v-deep .el-form-item__label {
  text-transform: capitalize;
  width: 100%;
  text-align: initial !important;
}
::v-deep .el-form-item__error {
  width: 100%;
  text-align: initial;
}
::v-deep .el-form-item__content {
  text-align: initial;
  text-transform: capitalize;
}
::v-deep .card label {
  text-align: initial;
  text-transform: capitalize;
}
</style>

<style lang="scss">
.img_ava {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
</style>
